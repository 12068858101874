<template>
  <div class="search-page">
    <div class="page-header">
      <woot-button
        icon="chevron-left"
        variant="smooth"
        size="small "
        class="back-button"
        @click="onBack"
      >
        {{ $t('GENERAL_SETTINGS.BACK') }}
      </woot-button>
    </div>
    <section class="search-root">
      <div class="include-archived">
        <woot-switch
          class="auto-offline--switch"
          :value="archivedOnly"
          @input="toggleArchivedOnlyConversations"
        />
        <label>{{ $t('SEARCH.ONLY_ARCHIVED_TOGGLE_TEXT') }}</label>
      </div>
      <div class="inbox-input">
        <multiselect
          v-model="selectedInboxes"
          class="no-margin"
          :options="inboxes"
          track-by="id"
          label="name"
          :multiple="true"
          :limit="2"
          :limit-text="count => '+' + count"
          :close-on-select="false"
          :clear-on-select="false"
          :hide-selected="true"
          :placeholder="$t('SEARCH.INBOX_INPUT.PLACEHOLDER')"
          selected-label
          :select-label="$t('SEARCH.INBOX_INPUT.ENTER_TO_SELECT')"
          :deselect-label="$t('SEARCH.INBOX_INPUT.ENTER_TO_REMOVE')"
          @input="handleInboxInput"
        />
      </div>
      <header>
        <search-header @search="onSearch" />
        <search-tabs
          v-if="query"
          :tabs="tabs"
          :selected-tab="activeTabIndex"
          @tab-change="tabChange"
        />
      </header>
      <div ref="scrollContainer" class="search-results" @scroll="handleScroll">
        <div v-if="showResultsSection">
          <search-result-contacts-list
            v-if="filterContacts"
            ref="contactsList"
            :is-fetching="uiFlags.contact.isFetching"
            :is-inited="needFetch"
            :contacts="contacts"
            :query="query"
            :show-title="isSelectedTabAll"
          />

          <search-result-messages-list
            v-if="filterMessages"
            ref="messagesList"
            :is-fetching="uiFlags.message.isFetching"
            :is-inited="needFetch"
            :messages="messages"
            :query="query"
            :show-title="isSelectedTabAll"
          />

          <search-result-conversations-list
            v-if="filterConversations"
            ref="conversationsList"
            :is-fetching="uiFlags.conversation.isFetching"
            :is-inited="needFetch"
            :conversations="conversations"
            :query="query"
            :show-title="isSelectedTabAll"
          />
        </div>
        <div v-else-if="showEmptySearchResults" class="empty">
          <fluent-icon icon="info" size="16px" class="icon" />
          <p class="empty-state__text">
            {{ $t('SEARCH.EMPTY_STATE_FULL', { query }) }}
          </p>
        </div>
        <div v-else class="empty text-center">
          <p class="text-center margin-bottom-0">
            <fluent-icon icon="search" size="24px" class="icon" />
          </p>
          <p class="empty-state__text">
            {{ $t('SEARCH.EMPTY_STATE_DEFAULT') }}
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SearchHeader from './SearchHeader.vue';
import SearchTabs from './SearchTabs.vue';
import SearchResultConversationsList from './SearchResultConversationsList.vue';
import SearchResultMessagesList from './SearchResultMessagesList.vue';
import SearchResultContactsList from './SearchResultContactsList.vue';

import { mixin as clickaway } from 'vue-clickaway';
import { mapGetters } from 'vuex';
import { CONVERSATION_EVENTS } from '../../../helper/AnalyticsHelper/events';
export default {
  components: {
    SearchHeader,
    SearchTabs,
    SearchResultContactsList,
    SearchResultConversationsList,
    SearchResultMessagesList,
  },
  mixins: [clickaway],
  data() {
    return {
      selectedTab: 'all',
      query: '',
      needFetch: false,
      offsets: { conversation: 0, message: 0, contact: 0 },
      scrolls: { conversation: 0, message: 0, contact: 0 },
      archivedOnly: false,
      lastQuery: '',
      selectedInboxes: [],
    };
  },

  computed: {
    ...mapGetters({
      contactRecords: 'conversationSearch/getContactRecords',
      hasMoreContactRecords: 'conversationSearch/hasMoreContactRecords',
      conversationRecords: 'conversationSearch/getConversationRecords',
      hasMoreConversationRecords:
        'conversationSearch/hasMoreConversationRecords',
      messageRecords: 'conversationSearch/getMessageRecords',
      hasMoreMessageRecords: 'conversationSearch/hasMoreMessageRecords',
      uiFlags: 'conversationSearch/getUIFlags',
      inboxes: 'inboxes/getInboxes',
    }),
    contacts() {
      const items = this.isSelectedTabAll
        ? this.contactRecords.slice(0, 10)
        : this.contactRecords;
      return items.map(contact => ({
        ...contact,
        type: 'contact',
      }));
    },
    conversations() {
      const items = this.isSelectedTabAll
        ? this.conversationRecords.slice(0, 10)
        : this.conversationRecords;
      return items.map(conversation => ({
        ...conversation,
        type: 'conversation',
      }));
    },
    messages() {
      const items = this.isSelectedTabAll
        ? this.messageRecords.slice(0, 10)
        : this.messageRecords;
      return items.map(message => ({
        ...message,
        type: 'message',
      }));
    },
    all() {
      return [...this.contacts, ...this.conversations, ...this.messages];
    },
    filterContacts() {
      return this.selectedTab === 'contacts' || this.isSelectedTabAll;
    },
    filterConversations() {
      return this.selectedTab === 'conversations' || this.isSelectedTabAll;
    },
    filterMessages() {
      return this.selectedTab === 'messages' || this.isSelectedTabAll;
    },
    totalSearchResultsCount() {
      return (
        this.contactRecords.length +
        this.conversationRecords.length +
        this.messageRecords.length
      );
    },
    tabs() {
      return [
        {
          key: 'all',
          name: this.$t('SEARCH.TABS.ALL'),
          count: -1,
          showBadge: false,
        },
        {
          key: 'contacts',
          name: this.$t('SEARCH.TABS.CONTACTS'),
          count: this.hasMoreContactRecords
            ? this.contactRecords.length + '+'
            : this.contactRecords.length,
        },
        {
          key: 'conversations',
          name: this.$t('SEARCH.TABS.CONVERSATIONS'),
          count: this.hasMoreConversationRecords
            ? this.conversationRecords.length + '+'
            : this.conversationRecords.length,
        },
        {
          key: 'messages',
          name: this.$t('SEARCH.TABS.MESSAGES'),
          count: this.hasMoreMessageRecords
            ? this.messageRecords.length + '+'
            : this.messageRecords.length,
        },
      ];
    },
    activeTabIndex() {
      const index = this.tabs.findIndex(tab => tab.key === this.selectedTab);
      return index >= 0 ? index : 0;
    },
    showEmptySearchResults() {
      return (
        this.totalSearchResultsCount === 0 &&
        this.uiFlags.isSearchCompleted &&
        !this.uiFlags.isFetching &&
        this.query
      );
    },
    showResultsSection() {
      return (
        (this.uiFlags.isSearchCompleted &&
          this.totalSearchResultsCount !== 0) ||
        this.uiFlags.isFetching
      );
    },
    isSelectedTabAll() {
      return this.selectedTab === 'all';
    },
  },
  beforeDestroy() {
    this.query = '';
    this.$store.dispatch('conversationSearch/clearSearchResults');
  },
  mounted() {
    this.$store.dispatch('conversationSearch/clearSearchResults');
  },
  methods: {
    onSearch(q) {
      this.query = q;
      this.lastQuery = q;
      this.needFetch = true;
      this.$store.dispatch('conversationSearch/clearSearchResults');
      this.offsets = { conversation: 0, message: 0, contact: 0 };
      const container = this.$refs.scrollContainer;
      container.scrollTop = 0;

      if (!q) {
        return;
      }
      this.$track(CONVERSATION_EVENTS.SEARCH_CONVERSATION);
      this.$store
        .dispatch('conversationSearch/fullSearch', {
          q,
          archivedOnly: this.archivedOnly,
          inboxes: this.selectedInboxes.map(inbox => inbox.id),
        })
        .then(() => {
          this.needFetch = false;
        });
    },
    handleInboxInput() {
      this.onSearch(this.lastQuery);
    },
    tabChange(tab) {
      const container = this.$refs.scrollContainer;
      container.scrollTop = 0;
      this.selectedTab = tab;
    },
    toggleArchivedOnlyConversations() {
      this.archivedOnly = !this.archivedOnly;
      const selectedTab = this.selectedTab;
      this.onSearch(this.lastQuery);
      this.selectedTab = selectedTab;
    },
    onBack() {
      if (window.history.length > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'home' });
      }
    },
    isAtEndOfScroll(contentRef) {
      const container = this.$refs.scrollContainer;
      const content = contentRef.$el;
      return (
        container.scrollTop + container.clientHeight >= content.clientHeight
      );
    },
    handleScroll() {
      switch (this.selectedTab) {
        case 'conversations':
          if (this.isAtEndOfScroll(this.$refs.conversationsList))
            this.fetchMoreItems('Conversation');
          break;
        case 'messages':
          if (this.isAtEndOfScroll(this.$refs.messagesList))
            this.fetchMoreItems('Message');
          break;
        case 'contacts':
          if (this.isAtEndOfScroll(this.$refs.contactsList))
            this.fetchMoreItems('Contact');
          break;
        default:
          break;
      }
    },
    fetchMoreItems(tabName) {
      const tn = tabName.toLowerCase();
      if (this.uiFlags[tn].isFetching) {
        return;
      }

      if (this[`hasMore${tabName}Records`]) {
        this.offsets[tn] += 10;
        this.scrolls[tn] = this.$refs.scrollContainer.scrollTop;

        this.$store
          .dispatch(`conversationSearch/${tn}Search`, {
            q: this.query,
            offset: this.offsets[tn],
          })
          .then(() => {
            this.$nextTick(() => {
              this.$refs.scrollContainer.scrollTop = this.scrolls[tn];
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-page {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.page-header {
  display: flex;
  padding: var(--space-normal);
}
.search-root {
  margin: 0 auto;
  max-width: 72rem;
  min-height: 32rem;
  width: 100%;
  height: calc(100% - 24px);
  padding: var(--space-normal);
  display: flex;
  position: relative;
  flex-direction: column;
  background: white;
  margin-top: var(--space-medium);
  border-radius: 1.2rem;

  .search-results {
    flex-grow: 1;
    height: 400px;
    overflow-y: auto;
    padding: 0 var(--space-small);
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--space-medium) var(--space-normal);
  border-radius: var(--border-radius-medium);
  margin-top: var(--space-large);
  .icon {
    color: var(--s-500);
  }
  .empty-state__text {
    text-align: center;
    color: var(--s-500);
    margin: var(--space-small);
  }
}

.include-archived {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-small);
  label {
    margin-left: var(--space-small);
  }
}
.inbox-input {
  margin-bottom: var(--space-small);
  z-index: 51;
}
.no-margin {
  &::v-deep .multiselect__strong {
    margin-right: var(--space-smaller) !important;
    margin-bottom: 0 !important;
    line-height: 0 !important;
    font-weight: 400 !important;
    font-size: var(--font-size-small) !important;
  }
  &::v-deep .multiselect__tag {
    max-width: 150px !important;
    @media screen and (max-width: 1250px) {
      max-width: 80px !important;
    }
  }
}
</style>
