<template>
  <section class="show-hoory">
    <div
      :class="{
        'show-hoory-no-access': showUpgrade,
      }"
    >
      <div class="show-hoory-header">
        <div>
          <span class="color-primary fs-normal fw-normal">
            {{ $t('INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.POWERED.SHOW') }}
          </span>
          <span class="color-primary fs-normal fw-medium">
            Powered by Hoory
          </span>
        </div>
        <woot-switch
          size="medium"
          class="show-hoory-switch"
          :value="showPoweredBy"
          :disabled="showUpgrade"
          @input="switchShowPoweredBy()"
        />
      </div>
      <div class="show-hoory-desc fw-normal">
        {{ $t('INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.POWERED.TEXT') }}
      </div>
    </div>

    <transition name="bounce">
      <div v-if="showUpgrade" class="show-hoory-upgrade">
        <div class="color-primary fs-small fw-normal">
          {{ $t('INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.POWERED.UPGRADE') }}
        </div>
        <div>
          <woot-button
            size="small"
            class="show-hoory-upgrade-btn"
            color-scheme="primary"
            @click="upgradePlan()"
          >
            {{ $t('INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.POWERED.BTN') }}
          </woot-button>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    showPoweredBySetting: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showPoweredBy: false,
    };
  },
  computed: {
    ...mapGetters({
      subscription: 'billing/getBillingInfo',
      currentUser: 'getCurrentUser',
    }),
    isBusinessPlan() {
      return this.subscription?.product.name.toLowerCase() === 'business';
    },
    showUpgrade() {
      return !this.isBusinessPlan && this.showPoweredBy;
    },
  },
  created() {
    this.showPoweredBy = this.showPoweredBySetting;
  },
  methods: {
    upgradePlan() {
      const path = `/app/accounts/${this.currentUser.account_id}/settings/billing`;
      return this.$router.push({ path });
    },
    switchShowPoweredBy() {
      this.showPoweredBy = !this.showPoweredBy;
      if (!this.showUpgrade) this.$emit('switch', this.showPoweredBy);
    },
  },
};
</script>

<style lang="scss" scoped>
.show-hoory {
  background-color: var(--d-600);
  border-radius: var(--border-radius-large);
  padding: var(--space-snug);
  margin-bottom: var(--space-one);
  .show-hoory-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .show-hoory-switch {
    border-radius: var(--border-radius-medium);
  }
  .show-hoory-desc {
    margin-right: var(--space-two);
    margin-top: var(--space-smaller);
    font-size: var(--font-size-small);
  }
  .show-hoory-no-access {
    opacity: 0.5;
  }
  .show-hoory-upgrade {
    background-color: var(--white);
    border-radius: var(--border-radius-large);
    padding: var(--space-snug);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--space-large);
    margin-top: var(--space-one);
    .show-hoory-upgrade-btn {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 125px;
      padding: var(--space-normal);
      border-radius: var(--border-radius-medium);
    }
  }
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>
